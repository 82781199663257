export const ROLES = [
  "visitor",
  "exhibitor",
  "delegate",
  "speaker",
  "media",
  "organizer",
  "crew",
  "workshop",
  "vip",
  "sponsor",
  "committee",
  "CTF",
  "CrisisSimulation",
  "staff",
  "volunteer",
  "panelist",
  "host",
  "moderator",
  "Hackathon",
  "DayPass",
  "Certificate",
  "visitorDay1",
  "visitorDay2",
  "Meeting",
  "OSHRM_Board_Members",
  "Corporates",
  "Student",
  "Meeting",
  "Vendors",
  "Board_Members",
];
