import React from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useQuery, gql, useMutation } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { GET_VERIFICATION_FEATURE } from "./RoleFormPage";

export default function RoleResponsePage() {
  const { eventId, role } = useParams();
  
  const { data: formData, refetch } = useQuery(GET_FORM, {
    variables: { eventId, role },
  });

  const { data, error } = useQuery(GET_RESPONSE, {
    variables: { eventId, role },
  });
  const [saveResponse] = useMutation(SAVE_RESPONSE);

  const onSave = async (r, f) => {
    saveResponse({
      variables: {
        eventId,
        role: r,
        response: f.response,
        acceptResponse: f.acceptResponse,
        rejectResponse: f.rejectResponse,
      },
    })
      .then((res) => alert(res.data.saveResponse))
      .then(() => refetch())
      .catch((e) => alert(JSON.stringify(e, null, 2)));
  };


  return (
    <Box>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ textTransform: "capitalize" }}
      >
        {role} Registration Response
      </Typography>
      {!!error && (
        <Typography color="error">
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Typography>
      )}
      <Stack
        direction="row"
        spacing={4}
        width="100%"
        divider={<Divider flexItem orientation="vertical" />}
      >
        <Stack spacing={1} divider={<Divider />} width={400}>
          <Typography variant="h6">Variables:</Typography>
          <Typography>$ID</Typography>
          <Typography>$ROLE</Typography>
          <Typography>$QR</Typography>
          <Typography>$EVENT_TITLE</Typography>
          <Typography>$BADGE_URL</Typography>
          <Typography>$CREATED</Typography>
          {formData?.form?.fields
            ?.map((f) => "$" + f.name)
            .map((v) => (
              <Typography>{v}</Typography>
            ))}
        </Stack>
        <Stack spacing={2} divider={<Divider />} width={800}>
          <ResponseSetup
            title=""
            onSubmit={(f) => onSave(role, f)}
            defaultValues={!!data ? data?.response : null}
            eventId={eventId}
            role={role}
          />
        </Stack>
      </Stack>
      <Box p={4} />
    </Box>
  );
}

function ResponseSetup({ title, onSubmit, defaultValues,eventId,role }) {
  const [feature, setFeature] = React.useState(null)
  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues: {
      response: "",
    },
  });
  React.useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues]);
  const { data: featureData, loading: featureLoading } = useQuery(GET_VERIFICATION_FEATURE, {
    variables: { eventId, role },
  });
  React.useEffect(() => {
    if (featureData && featureData.mailVerificationFeature) {
      let parsedData = [];
      try {
        parsedData = JSON.parse(featureData.mailVerificationFeature.features);
      } catch (err) {
        console.error("Error parsing the data:", err);
      }
      if (Array.isArray(parsedData)) {
        setFeature(parsedData);
      }
    }
  }, [featureData]);
  if (featureLoading) return <span>Loading...</span>;

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={1} my={1}>
        <Typography fontWeight={"bold"}>{title}</Typography>
        <Stack spacing={1}>
          <Controller
            control={control}
            name="response"
            rules={{ required: "Required" }}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <TextField
                inputRef={ref}
                {...field}
                required
                error={!!error}
                helperText={error?.message}
                fullWidth
                label="Response Body"
                multiline
                minRows={4}
              />
            )}
          />
        </Stack>
        <Stack>
          {feature?.includes("emailVerification") && (
            <>
              <Controller
                control={control}
                name="acceptResponse"
                rules={{ required: "Required" }}
                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                  <TextField
                    inputRef={ref}
                    {...field}
                    required
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                    label="Accept Response Body"
                    multiline
                    minRows={4}
                  />
                )}
              />
              <Controller
                control={control}
                name="rejectResponse"
                rules={{ required: "Required" }}
                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                  <TextField
                    inputRef={ref}
                    {...field}
                    required
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                    label="Reject Response Body"
                    multiline
                    minRows={4}
                  />
                )}
              />
            </>)}
        </Stack>
        <Stack direction="row" spacing={1}>
          <LoadingButton
            loading={formState.isSubmitting}
            type="submit"
            color="primary"
            variant="contained"
          >
            Save
          </LoadingButton>
          <Button color="error" onClick={() => reset()}>
            Reset
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

const GET_RESPONSE = gql`
  query ($eventId: String!, $role: String!) {
    response(eventId: $eventId, role: $role){
      response
      acceptResponse
      rejectResponse
    }
  }
`;

const SAVE_RESPONSE = gql`
  mutation ($eventId: String!, $role: String!, $response: String!, $acceptResponse: String, $rejectResponse: String) {
    saveResponse(eventId: $eventId, role: $role, response: $response, acceptResponse: $acceptResponse, rejectResponse: $rejectResponse)
  }
`;

const GET_FORM = gql`
  query ($eventId: String!, $role: ROLE!) {
    form(eventId: $eventId, role: $role) {
      fields {
        name
      }
    }
  }
`;
